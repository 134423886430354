<template>
  <div class="management-page dashboard-content" style="position: relative;">
    <!-- ^ Add position: relative here if you want the absolute positioning inside this container -->

    <!-- Certification Progress at Top Left -->
    <div v-if="!isCryptoEntity" class="certification-progress" style="position: absolute; top: 10px; right: 10px;">
      <strong>{{ certificationPercentage }}% Certified</strong>
    </div>

    <!-- Page Header and Merchant Selector -->
    <div v-if="!isCryptoEntity" class="d-flex flex-wrap align-items-center mb-4">
      <div class="me-auto">
        <h1 class="page-title">Self-Certification</h1>
        <p class="page-description">
          Please use our <strong>Sandbox</strong> environment
          (recommended) to verify that your setup for charging and refunding transactions works correctly
          before moving to production.
        </p>
      </div>
      <!-- Eventually Add back in -->
      <!--
      <div class="merchant-selector">
        <label for="merchantSelect" class="form-label">Select Merchant</label>
        <select id="merchantSelect" v-model="selectedClientId" class="form-select" style="min-width: 200px;">
          <option v-for="merchant in membershipsByMerchant" :key="merchant.id" :value="merchant.id">
            {{ merchant.name }}
          </option>
        </select>
      </div>
      -->
    </div>

    <!-- Steps to Certify -->
    <div v-if="!isCryptoEntity" class="certification-steps mb-4">
      <h2 class="section-title">Certification Steps</h2>
      <ul>
        <li v-if="testDateCompletion">
          <strong>Certification Complete!</strong>
        </li>
        <li>
          <strong>Run a Test Charge:</strong> In the Virtual Terminal or via our charge API, run a small test charge to simulate a real transaction.
        </li>
        <li>
          <strong>{{ testCharges }}</strong> Charges Have Been Run Thus Far
        </li>
        <li>
          <strong>Refund the Charge:</strong> After you've completed the charge, call our void API or go to the Reporting page to initiate a refund for that test transaction.
        </li>
        <li>
          <strong>{{ testRefunds }}</strong> Refunds Have Been Run Thus Far
        </li>
        <li>
          <strong>Verify in Reporting:</strong> Go to the Reporting page or call our v1/report API and ensure both the test charge and its corresponding refund appear as expected.
        </li>
        <li>
          <strong>{{ testReporting }}</strong> Reporting Views Have Been Run Thus Far
        </li>
      </ul>
    </div>

    <!-- Sandbox Link Section -->
    <div v-if="sandboxLink && !isCryptoEntity" class="virtual-terminal mb-4">
      <h3 class="section-title">Sandbox Environment Access</h3>
      <p class="mb-2">
        Click the button below to open the sandbox environment in a new tab. If you have any issues
        accessing the sandbox, please contact our support team.
      </p>
      <div class="row gap-2">
        <div class="col-12 col-md-3 metric-cards d-flex justify-content-center align-items-center" style="padding: 0 !important;">
          <a :href="sandboxLink" class="btn btn-primary" style="margin: 20px;" target="_blank" rel="noopener noreferrer">
            {{ sandboxLinkText }}
          </a>
        </div>
      </div>
    </div>

    <!-- After Certification -->
    <div v-if="!isCryptoEntity" class="post-certification">
      <h3 class="section-title">Next Steps</h3>
      <p>
        After you've successfully completed the test charge, refund, and verification in the reporting page,
        in the sandbox environment you have fully self-certified your integration. You can now:
      </p>
      <ul>
        <li v-if="!isSandboxEnvironment">Ensure production approval by completing an enrollment. <strong>Your current enrollment status is {{ currentEnrollmentStatus }}</strong></li>
        <li><a :href="partnerEnrollmentLink">Partners Enroll Here</a> and <a :href="merchantEnrollmentLink">Merchants Enroll Here</a></li>
        <li>Proceed to use the platform in a production environment (if approved).</li>
        <li>Contact our support, csm@tripleplaypay.com, if you face any issues or have any questions going forward.</li>
      </ul>
      <p>
        Thank you for taking the time to self-certify. We look forward to
        supporting your business and providing a seamless payment experience.
      </p>
    </div>

    <!-- Crypto KYC Section -->
    <div v-if="cryptoProvider === 'forumpay'" class="crypto-kyc mb-4 mt-4">
      <h3 class="section-title">Cryptocurrency Access</h3>
      <div v-if="cryptoEnabled" class="alert alert-success" role="alert">
        <p><strong>Cryptocurrency enabled!</strong> Your account has been verified and cryptocurrency payments are now available.</p>
        <p>Cryptocurrency Provider: {{ cryptoProvider }}</p>
        <p>Status: {{ cryptoStatus }}</p>
      </div>
      <div v-else class="alert alert-info" role="alert">
        <p>To enable cryptocurrency payments, you'll need to complete the Know Your Customer (KYC) verification process.</p>
        <p>Once verified, you'll gain access to our cryptocurrency payment options through {{ cryptoProvider }}.</p>
        <div class="mt-3">
          <a :href="cryptoKycLink" class="btn btn-primary" target="_blank" rel="noopener noreferrer">
            Complete KYC Verification
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import http from '@/services/http';
import { useStore } from 'vuex';
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import { useToast } from 'vue-toastification';

const toast = useToast();
let toastdefault = {
  position: "top-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};

const store = useStore();
const email = computed(() => store.getters.getUserEmail);
const defaultClientId = computed(() => store.getters.defaultClientId);
const membershipsByMerchant = computed(() => store.getters.membershipsByMerchant);
const userHasVirtualTerminalPermission = computed(() => {
  return store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL);
});
const hasPermissions = computed(() => store.getters.merchantHasPermissions);
const virtualTerminalPermissions = computed(() => {
  if (userHasVirtualTerminalPermission.value) {
    return true;
  } else {
    return !hasPermissions.value;
  }
});

const isSandboxEnvironment = ref(false);
if (window.location.hostname.startsWith('sandbox.')) {
  isSandboxEnvironment.value = true;
}

const isCryptoEntity = computed(() => store.getters.isCryptoEntity);
const partnerEnrollmentLink = computed(() =>`https://tripleplaypay.com/partner-signup/${store.getters.getParentId}/${store.getters.defaultClientId}`);
const merchantEnrollmentLink = computed(() =>`https://tripleplaypay.com/enroll/${store.getters.getParentId}/${store.getters.defaultClientId}`);

const selectedClientId = ref(defaultClientId.value);
const testCharges = ref(0);
const testRefunds = ref(0);
const testReporting = ref(0);
const testDateCompletion = ref(null);
const isSubmitting = ref(false);
const sandboxLink = ref("");
const currentEnrollmentStatus = ref("");
const sandboxLinkText = ref('Sandbox Link Loading...');

// Crypto status related refs
const cryptoProvider = ref("");
const cryptoStatus = ref("");
const cryptoEnabled = ref(false);
const cryptoKycLink = ref("");
const kycRefreshInterval = ref(null);

const fixTheLinkPerEnvironment = (theLink) => {
  const parsedUrl = new URL(theLink);
  parsedUrl.protocol = window.location.protocol;
  parsedUrl.hostname = window.location.hostname;
  parsedUrl.port = window.location.port;
  return parsedUrl.toString();
};

const loadCertificateProgress = (clientId) => {
  isSubmitting.value = true;
  http.get(`/api/certification?id=${clientId}`)
      .then(response => {
        isSubmitting.value = false;
        if (response.status === 200 && response.data.message) {
          testCharges.value = response.data.message.charges;
          testRefunds.value = response.data.message.refunds;
          testReporting.value = response.data.message.reporting;
          testDateCompletion.value = response.data.message.date_time_completed;
        }
      })
      .catch(error => {
        isSubmitting.value = false;
        console.error('An error occurred while loading certification data:', error);
      });
};

const loadCurrentEnrollmentStatus = (clientId) => {
  isSubmitting.value = true;
  http.get(`/api/enroll?id=${clientId}`)
      .then(response => {
        isSubmitting.value = false;
        if (response.status === 200 && response.data.message) {
          currentEnrollmentStatus.value = response.data.message.application_status;
        } else {
          currentEnrollmentStatus.value = "Unknown";
        }
      })
      .catch(error => {
        isSubmitting.value = false;
        console.error('An error occurred while loading enrollment status:', error);
      });
};

const loadSandboxLink = () => {
  isSubmitting.value = true;
  const data = { email: email.value };
  http.post(`/api/sandboxlink`, data)
      .then(response => {
        if (response.status === 200 && response.data.message) {
          isSubmitting.value = false;
          sandboxLinkText.value = "Click Here"
          sandboxLink.value = response.data.message;
        } else {
          sandboxLinkText.value = ""
          isSubmitting.value = false;
        }
      })
      .catch(error => {
        isSubmitting.value = false;
        console.error('An error occurred while loading sandbox link:', error);
      });
};

// Function to refresh KYC SSO link
const refreshKycLink = (clientId) => {
  http.get(`/api/crypto/kyc-sso?merchant-id=${clientId}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.message.crypto_kyc_link) {
          cryptoKycLink.value = response.data.message.crypto_kyc_link;
        }
      })
      .catch(error => {
        console.error('An error occurred while refreshing KYC link:', error);
      });
};

// Stop refreshing KYC link
const stopKycLinkRefresh = () => {
  if (kycRefreshInterval.value) {
    clearInterval(kycRefreshInterval.value);
    kycRefreshInterval.value = null;
  }
};

// Start refreshing KYC link every 5 seconds
const startKycLinkRefresh = (clientId) => {
  // Clear any existing interval first
  stopKycLinkRefresh();

  // Set new interval
  kycRefreshInterval.value = setInterval(() => {
    refreshKycLink(clientId);
  }, 4000);
};

// Function to load crypto status
const loadCryptoStatus = (clientId) => {
  isSubmitting.value = true;
  http.get(`/api/crypto/status?merchant-id=${clientId}`)
      .then(response => {
        isSubmitting.value = false;
        if (response.status === 200 && response.data) {
          cryptoProvider.value = response.data.message.crypto_provider;
          cryptoStatus.value = response.data.message.crypto_status;

          // Check if crypto status has changed
          const previousEnabled = cryptoEnabled.value;
          cryptoEnabled.value = response.data.message.crypto_enabled;
          cryptoKycLink.value = response.data.message.crypto_kyc_link;

          // Toggle KYC link refresh based on crypto status
          if (!cryptoEnabled.value && cryptoProvider.value === 'forumpay') {
            console.log("Starting KYC Link refresh");
            startKycLinkRefresh(clientId);
          }
        }
      })
      .catch(error => {
        isSubmitting.value = false;
        console.error('An error occurred while loading crypto status:', error);
      });
};

// --------------------- ADD THESE FOR THE PROGRESS --------------------- //
const certificationStepsCompleted = computed(() => {
  // If the date_time_completed is set, treat it as 100%
  if (testDateCompletion.value) {
    return 3; // all steps done
  }

  let steps = 0;
  if (testCharges.value > 0) steps++;
  if (testRefunds.value > 0) steps++;
  if (testReporting.value > 0) steps++;
  return steps;
});

const certificationPercentage = computed(() => {
  const totalSteps = 3;
  return Math.round((certificationStepsCompleted.value / totalSteps) * 100);
});
// --------------------------------------------------------------------- //

watch(selectedClientId, (newClientId) => {
  if (newClientId) {
    // Stop any existing KYC refresh interval
    stopKycLinkRefresh();

    loadCertificateProgress(newClientId);
    loadCurrentEnrollmentStatus(newClientId);
    loadCryptoStatus(newClientId);
  }
});

// Watch for changes in cryptoEnabled to start/stop refresh interval
watch(cryptoEnabled, (isEnabled, prevEnabled) => {
  if (!isEnabled && prevEnabled !== isEnabled) {
    startKycLinkRefresh(selectedClientId.value);
  } else if (isEnabled && prevEnabled !== isEnabled) {
    stopKycLinkRefresh();
  }
});

onMounted(() => {
  loadSandboxLink();
  loadCertificateProgress(defaultClientId.value);
  loadCurrentEnrollmentStatus(defaultClientId.value);
  loadCryptoStatus(defaultClientId.value);

  // Initialize KYC link refresh if needed
  if (cryptoProvider.value && !cryptoEnabled.value) {
    startKycLinkRefresh(defaultClientId.value);
  }
});

// Clean up intervals when component is unmounted
onUnmounted(() => {
  stopKycLinkRefresh();
});
</script>

<style scoped>
.dashboard-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative; /* if you want absolute elements inside */
}

.page-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.page-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

.section-title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.certification-steps {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.certification-steps ul {
  padding-left: 20px;
}

.metric-cards {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.virtual-terminal {
  padding: 20px;
  border-radius: 8px;
  background: #f8f9fa;
}

.post-certification {
  padding: 20px;
  border-radius: 8px;
  background: #f9f9f9;
  margin-top: 20px;
}

.crypto-kyc {
  padding: 20px;
  border-radius: 8px;
  background: #f9f9f9;
}

.merchant-selector {
  margin-left: 20px;
}

.merchant-selector .form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.certification-progress {
  /* optional styling for your progress container */
  background: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
</style>