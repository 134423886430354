<script setup>
import { ref, onMounted, reactive, watch, computed } from 'vue';

import BaseSpinnerPartial from '@/components/base/BaseSpinnerPartial.vue';
import http from '@/services/http';
import { useStore } from 'vuex';

import BaseModal from '@/components/base/BaseModal.vue';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import { PERMISSION_PRIMITIVES, PRIMARY_CLIENT_ID } from '@/config/constants';

// Get user data
const store = useStore();
const defaultClientName = computed(() => store.getters.defaultClientid);
const defaultClientId = computed(() => store.getters.defaultClientId);
const toast = useToast();
const router = useRouter();
console.log(
    'fault clientName',
    defaultClientName.value,
    'Default client ID',
    defaultClientId.value
);

// const isLarge = ref(true);
const isUserModalOpen = ref(false);
const isGroupModalOpen = ref(false);
const isRoleModalOpen = ref(false);
const isEditingRoleName = ref(false);
// const isEditingGroupName = ref(false);
const selectedUser = reactive({
  id: null,
  name: '',
  role: {},
  groups_member: [],
  sub_entities: []
});

const selectedGroup = ref('');

/**
 * @typedef {{
 *   order: number
 *   name: string
 *   type: string
 *   values?: Array<string>
 *   required?: Boolean
 *   is_hidden?: Boolean
 * }} CustomField
 */

/**
 * @typedef {{
 *   id: string
 *   name: string
 *   granted_permissions: Array<{
 *     name: string
 *   }>
 *   custom_field_access: Record<string, 'show' | 'require'>
 *   merchant: {
 *     id: string
 *     name: string
 *   }
 * }} PermissionsPageRole
 */

/**
 * @typedef {{
 *   url: string
 *   customFields: Array<CustomField>
 *   id?: string
 *   name?: string
 *   is_active?: boolean
 * }} VtMerchantInfo
 */

/**
 * @typedef {{
 *   id: string
 *   result: VtMerchantInfo | Array<VtMerchantInfo>
 * }} VtMerchantInfoResult
 */

/**
 * @type {import("vue").Ref<PermissionsPageRole>}
 */
const selectedRole = ref('');
/**
 * @type {import("vue").ComputedRef<string[]>}
 */
const selectedRoleAvailableFields = computed(() => {
  return Object.entries(selectedRole.value?.custom_field_access || {})
      // eslint-disable-next-line no-unused-vars
      .filter(([ignored, v]) => v === 'show')
      .map(([k]) => k);
});
/**
 * @type {import("vue").ComputedRef<string[]>}
 */
const selectedRoleRequiredFields = computed(() => {
  return Object.entries(selectedRole.value?.custom_field_access || {})
      // eslint-disable-next-line no-unused-vars
      .filter(([ignored, v]) => v === 'require')
      .map(([k]) => k);
});
// const selectedMerchant = ref('');
// const selectedAddGroup = ref('');
const selectedPermission = ref('');
const selectedRoleFilter = ref('');
// const selectedGroupFilter = ref('');
const iframeLink = ref('');
/**
 * @typedef {{
 *   id: string
 *   email: string
 *   groups_member: Array<{
 *     id: string
 *     name: string
 *   }>
 *   sub_entities: Array<{
 *     id: string
 *     name: string
 *     merchant: {
 *       id: string
 *       name: string
 *     }
 *   }>
 *   account: {
 *     id: string
 *     first_name: string
 *     last_name: string
 *     memberships: Array<{
 *       id: string
 *       merchant: {
 *         id: string
 *         name: string
 *       }
 *       role: {
 *         id: string
 *         name: string
 *       }
 *     }>
 *   }
 * }} PermissionsPageUser
 */

/**
 * @type {import("vue").Ref<Array<PermissionsPageUser>>}
 */
const users = ref([]);

// const groups = ref([]);

/**
 * @type {import("vue").Ref<Array<{name: string}>>}
 */
const permissions = ref([]);

const merchants = ref([]);

/**
 * @type {import("vue").Ref<Array<CustomField>>}
 */
const customFields = ref([]);

/**
 * @type {import("vue").Ref<Array<VtMerchantInfoResult>>}
 */
const vtMerchants = ref([]);

/**
 * @type {import("vue").ComputedRef<Record<string, Array<VtMerchantInfo>>>}
 */
const vtMerchantsByMerchantId = computed(() => {
  const result = {};

  vtMerchants.value.forEach(item => {
    // Handle both single and array responses
    if (Array.isArray(item.result)) {
      result[item.id] = item.result;
    } else {
      result[item.id] = [item.result];
    }
  });

  return result;
});

// For storing selected terminal for custom field access
const selectedTerminalId = ref({});

// const newGroupName = ref('');
const newRoleName = ref('');
// const searchQuery = ref('');

/*
const removingMerchantFromGroup = ref(false);
const addingMerchantToGroup = ref(false);
const savingGroupName = ref(false);

async function addMerchantToGroup(groupID, merchantID) {
  addingMerchantToGroup.value = true;
  const groupIndex = groups.value.findIndex((group) => group?.id === groupID);

  try {
    if (
      groupIndex !== -1 &&
      groups.value[groupIndex]?.affiliated_merchants?.some(
        (m) => m.id === merchantID
      )
    ) {
      return;
    }
    const response = await http.post(
      `/api/rbac/${defaultClientId.value}/groups/${groupID}/merchants/${merchantID}`
    );
    console.log(
      'Adding merchant:',
      selectedMerchant.value,
      'to group:',
      selectedGroup.value
    );

    groups.value[groupIndex].affiliated_merchants = response.data.message;

    if (selectedGroup.value.id === groupID) {
      selectedMerchant.value = '';
      selectedGroup.value.affiliated_merchants = response.data.message;
    }
  } catch (error) {
    console.error('Error adding merchant to group:', error);
  } finally {
    addingMerchantToGroup.value = false;
  }
}
*/

/**
 * @type {import("vue").Ref<Array<PermissionsPageRole>>}
 */
const roles = ref([]);

watch([() => defaultClientId.value], ([newClientId], [oldClientId]) => {
  // on first load, handles null merchant ID to update on incoming client ID
  // and refresh data

  if (newClientId && !oldClientId) {
    loadPermissionsData();
  }
});

/**
 * Example Groups list response.data.message:
 *
 * ```
 * [
 {
            "id": "0ce9aa28-4a0e-4a68-b67f-7fe7c401399a",
            "is_deleted": false,
            "created_at": "2024-04-11T23:49:22.022310+00:00",
            "updated_at": "2024-04-11T23:49:22.022314+00:00",
            "name": "Region 3",
            "merchant_id": "4a731b24-0beb-457c-9585-2362c6a53cf5",
            "affiliated_merchants": [
                {
                    "id": "d4c374d3-34dd-430c-9e93-8f0925ae99a1",
                    "level": "Merchant",
                    "name": "RBAC Test Merchant 1",

                },
                {
                    "id": "456f4657-b6a1-4960-b501-93f894e4a695",
                    "level": "Merchant",
                    "name": "RBAC Test Merchant 2",
                },
                {
                    "id": "977c0d79-fc94-4d84-b7c4-6e1aab75ae94",
                    "level": "Merchant",
                    "name": "RBAC Test Merchant 3",
                }
            ]
        }
 ]
 * ```
 */
/*
async function getGroups() {
  const response = await http.get(`/api/rbac/${defaultClientId.value}/groups`);

  groups.value = response.data.message;

  console.log('Groups response', response.data);
}
*/

/**
 * Example Roles list response.data.message:
 *
 * ```
 *  [
 {
            "id": "b0200649-5ce9-47c8-baff-9d527864b1b6",
            "name": "Admin",
            "granted_permissions": [
                {
                    "id": "63162381-194d-47bd-b41e-5c7c1a4323ac",
                    "name": "ACH"
                },
                {
                    "id": "2ecf6dff-7566-4547-ae54-8970f2250bc7",
                    "name": "Adjustment"
                },
                {
                    "id": "f81eef73-4c9e-43af-af74-d8f6c1fc30ee",
                    "name": "Credit"
                },
                {
                    "id": "6168d29e-19e9-4896-a592-2e463e0eebcd",
                    "name": "Refund"
                },
                {
                    "id": "974a1bf3-c340-44da-95b4-230d07f7681d",
                    "name": "Swipe"
                },
                {
                    "id": "ad1cedd3-b46b-4146-9d67-72ba45e82369",
                    "name": "Terminals"
                },
                {
                    "id": "12072c98-51f2-4d55-9a36-224d49970955",
                    "name": "Void"
                }
            ]
        },
 {
            "id": "66782468-e9fa-480a-8659-71ef6f9aa984",
            "name": "User",
            "granted_permissions": [
                {
                    "id": "63162381-194d-47bd-b41e-5c7c1a4323ac",
                    "name": "ACH",
                },
                {
                    "id": "2ecf6dff-7566-4547-ae54-8970f2250bc7",
                    "name": "Adjustment"
                },
                {
                    "id": "f81eef73-4c9e-43af-af74-d8f6c1fc30ee",
                    "name": "Credit"
                }
            ]
        }
 ]
 * ```
 */
async function getRoles() {
  const response = await http.get(`/api/rbac/${defaultClientId.value}/roles`);

  roles.value = response.data.message.map(e => {
    [
      'granted_permissions',
      'hideable_fields',
      'omittable_fields',
    ].forEach(f => e[f] = e[f] || []);
    [
      'custom_field_access',
    ].forEach(f => e[f] = e[f] || {});
    return e;
  })
      .sort((a, b) => a.merchant.name.localeCompare(b.merchant.name) || a.name.localeCompare(b.name));

  console.log('Roles response', response.data);
}

/**
 * Example users list response.data.message:
 *
 * ```
 * [
 *         {
            "id": "e0f588c4-d61a-47a6-b526-84626cc16288",
            "name": "Jeremy Bollins"
            "email": "u9@4a731b24-0beb-457c-9585-2362c6a53cf5.TriplePlayPay.com",
            "label": null,
            "groups_member": [
                {
                    "id": "8af8f94a-b3a3-4293-8269-80c151a8c1d2",
                    "name": "Region 1",
                }
            ]
        }
 ]
 * ```
 */
async function getUsers() {
  const response = await http.get(`/api/rbac/${defaultClientId.value}/users?with_recursion=true`);

  users.value = response.data.message ?? [];

  /*
  if (users.value.length > 0) {
    users.value.forEach((user) => {
      if (!user.role) {
        user.role = {};
      }
    });
  }
  */

  console.log('Users response', response.data);
}

/**
 * Example Permissions list response.data.message:
 * ```
 *    [
 {
            "id": "d221bcae-5f3c-40e4-98ef-70252e986e04",
            "name": "ACH",
        },
 {
            "id": "7ac4badd-da3b-4af2-ac1c-c5d6b4e7a5cd",
            "name": "Adjustment",
        }
 ]
 ```
 */
async function getPermissions() {
  if (defaultClientId.value === PRIMARY_CLIENT_ID) {
    permissions.value = [];
    return;
  }

  const response = await http.get(
      `/api/rbac/${defaultClientId.value}/permissions`
  );

  permissions.value = response.data.message;

  if (!permissions.value || permissions.value.length === 0) {
    toast.error('Merchant does not have any permissions configured.');
    // router.push('/');
  }

  console.log('Permissions response', response.data);
}

async function getMerchants() {
  const response = await http.get(`/api/user/apikeys`);

  merchants.value = response.data.message;

  console.log('Merchants response', response.data);
}

async function loadCustomFields() {
  try {
    // We'll now fetch all terminal data for each merchant
    const merchantResponse = await http.get(`/api/permissions/merchants`);

    if (merchantResponse.data?.message) {
      vtMerchants.value = merchantResponse.data.message;

      // Initialize selected terminals for each merchant
      vtMerchants.value.forEach(item => {
        if (Array.isArray(item.result) && item.result.length > 0) {
          // Select the first active terminal or first terminal if none active
          const activeTerminal = item.result.find(t => t.is_active) || item.result[0];
          if (activeTerminal) {
            selectedTerminalId.value[item.id] = activeTerminal.id;

            // Set the custom fields from this terminal
            if (activeTerminal.customFields && activeTerminal.customFields.length > 0) {
              customFields.value = [...customFields.value, ...activeTerminal.customFields];
            }
          }
        } else if (item.result && !Array.isArray(item.result)) {
          // Single terminal case
          selectedTerminalId.value[item.id] = item.result.id;

          // Set the custom fields from this terminal
          if (item.result.customFields && item.result.customFields.length > 0) {
            customFields.value = [...customFields.value, ...item.result.customFields];
          }
        }
      });

      // Deduplicate custom fields by name
      customFields.value = Array.from(
          new Map(customFields.value.map(item => [item.name, item])).values()
      );
    }
  } catch (error) {
    console.error('Error fetching terminal data:', error);
  }
}

/**
 * @type {import("vue").Ref<{id: string, name: string, merchant: {id: string, name: string}}[]>}
 */
const subEntities = ref([]);
const loadingSubEntities = ref(false);
/*
const selectedSubLocation = ref('');
const addingSubLocationToGroup = ref(false);
*/
async function getAllSubEntities() {
  const merchantId = defaultClientId.value;

  loadingSubEntities.value = true;
  try {
    const response = await http.get(`/api/rbac/${merchantId}/sub-entities`);
    subEntities.value = response.data.message;
    console.log('Sub-entities response', subEntities);
    return subEntities;
  } catch (error) {
    console.error('Error fetching sub-entities:', error);
    return [];
  } finally {
    loadingSubEntities.value = false;
  }
}

const loadingPermissionsPageData = ref(true);
/**
 * this gets all the data needed for the UI
 * on the permissions page
 */
async function loadPermissionsData() {
  loadingPermissionsPageData.value = true;
  try {
    await Promise.all([
      // getGroups(),
      getRoles(),
      getUsers(),
      getPermissions(),
      getMerchants(),
      loadCustomFields(),
      getAllSubEntities()
    ]);
  } catch (error) {
    console.error('Failed to load permissions page data:', error);
  } finally {
    let gp = store.getters.grantedPermissions;
    if (gp?.length) {
      if (!gp.includes(PERMISSION_PRIMITIVES.PERMISSIONS)) {
        toast.error('User does not have permission to view the permissions page.');
        router.push('/');
      } else {
        loadingPermissionsPageData.value = false;
      }
    } else {
      loadingPermissionsPageData.value = false;
    }
  }
}
if (defaultClientId.value) {
  loadPermissionsData();
}

const addingPermissionToRole = ref(false);
async function addPermissionToRole(roleId, permissionName) {
  addingPermissionToRole.value = true;
  try {
    const response = await http.post(
        `/api/rbac/${defaultClientId.value}/roles/${roleId}/permissions/${permissionName}`
    );
    console.log('Add Permission Result', response.data.message);
    console.log('Adding permission:', permissionName, 'to role:', roleId);
    const roleIndex = roles.value.findIndex((role) => role.id === roleId);
    console.log('Role index:', roleIndex);
    if (roleIndex !== -1) {
      const permissions = roles.value[roleIndex].granted_permissions;
      if (
          !permissions?.some(
              (permission) => permission.name === permissionName
          )
      ) {
        // console.log('Permission added to role:', permission);
        roles.value[roleIndex].granted_permissions = response.data.message;
        selectedPermission.value = '';
      }
    }
  } catch (error) {
    console.error('Error adding permission to role:', error);
  } finally {
    addingPermissionToRole.value = false;
  }
}

/*
async function removeMerchantFromGroup(merchantId, groupId) {
  removingMerchantFromGroup.value = true;
  const groupIndex = groups.value.findIndex((group) => group?.id === groupId);

  try {
    if (
      groupIndex === -1 &&
      !groups.value[groupIndex]?.affiliated_merchants?.some(
        (m) => m.id === merchantId
      )
    ) {
      console.log('Merchant not found in group:', merchantId);
      return;
    }
    const response = await http.delete(
      `/api/rbac/${defaultClientId.value}/groups/${groupId}/merchants/${merchantId}`
    );
    console.log('Removing merchant:', merchantId, 'from group:', groupId);

    groups.value[groupIndex].affiliated_merchants = response.data.message;

    if (selectedGroup.value.id === groupId) {
      selectedGroup.value.affiliated_merchants = response.data.message;
    }
  } catch (error) {
    console.error('Error removing merchant from group:', error);
  } finally {
    removingMerchantFromGroup.value = false;
  }
}

async function updateGroupName(groupID, name) {
  if (!name) return;

  savingGroupName.value = true;
  try {
    const response = await http.patch(
      `/api/rbac/${defaultClientId.value}/groups/${selectedGroup.value.id}`,
      {
        name: name?.trim()
      }
    );

    if (selectedGroup.value.id === groupID) {
      selectedGroup.value.name = response.data.message?.name;
    }
    const groupIndex = groups.value.findIndex((group) => group?.id === groupID);
    if (groupIndex !== -1) {
      groups.value[groupIndex].name = response.data.message.name;
    }
    isEditingGroupName.value = false;
    users.value.forEach((user) => {
      const groupMemberIndex = user.groups_member?.findIndex(
        (group) => group.id === groupID
      );
      if (user.groups_member && groupMemberIndex !== -1) {
        user.groups_member[groupMemberIndex].name = name.trim();
      }
    });
  } catch (error) {
    console.error('Error updating group name:', error);
  } finally {
    savingGroupName.value = false;
  }
}

const selectingNewRoleForUser = ref(false);
const assigningRoleToUser = ref(false);
async function assignRoleToUser(userId, roleId) {
  assigningRoleToUser.value = true;

  console.log('Assigning role:', roleId, 'to user:', userId);
  try {
    const response = await http.put(
      `/api/rbac/${defaultClientId.value}/users/${userId}/role/${roleId}`
    );
    const user = users.value.find((user) => user.id === userId);
    if (user) {
      user.role = response.data.message?.role; // Update the role locally after successful patch
      console.log(`Role ${roleId} assigned to user ${userId} successfully.`);
    } else {
      console.error(`User with ID ${userId} not found.`);
    }
    if (selectedUser.id === userId) {
      selectedUser.role = response.data.message?.role;
    }
    selectingNewRoleForUser.value = false;
  } catch (error) {
    console.error(`Error assigning role ${roleId} to user ${userId}:`, error);
  } finally {
    assigningRoleToUser.value = false;
  }
  // } else {
  //   console.error(`Role ${roleId} does not exist.`);
  // }
}

const deletingGroup = ref(false);
// Function to delete a group
async function deleteGroup(groupId) {
  deletingGroup.value = true;
  try {
    await http.delete(`/api/rbac/${defaultClientId.value}/groups/${groupId}`);
    console.log('Group deleted:', groupId);
    groups.value = groups.value.filter((group) => group.id !== groupId); // Update local state by removing the deleted group
    users.value.forEach((user) => {
      user.groups_member = user.groups_member.filter(
        (group) => group.id !== groupId
      );
    });
    selectedGroup.value = '';
    // isGroupModalOpen.value = false; // Close the group modal
  } catch (error) {
    console.error('Error deleting group:', error);
  } finally {
    deletingGroup.value = false;
  }
}

const addingNewGroup = ref(false);
// Function to create a new group
async function createNewGroup(groupName) {
  addingNewGroup.value = true;
  try {
    const response = await http.post(
      `/api/rbac/${defaultClientId.value}/groups`,
      { name: groupName?.trim() }
    );
    console.log('Group created:', response.data.message);
    groups.value.push(response.data.message); // Update local state with new group
  } catch (error) {
    console.error('Error creating new group:', error);
  } finally {
    addingNewGroup.value = false;
  }
}
*/

const creatingNewRole = ref(false);
// Function to create a new role
async function createNewRole(roleName) {
  creatingNewRole.value = true;
  try {
    const response = await http.post(
        `/api/rbac/${defaultClientId.value}/roles`,
        { name: roleName },
        {
          'Content-Type': 'application/json'
        }
    );
    newRoleName.value = '';
    console.log('Role created:', response.data.message);
    roles.value.push(response.data.message); // Update local state with new role
  } catch (error) {
    console.error('Error creating new role:', error);
  } finally {
    creatingNewRole.value = false;
  }
}

const removingPermissionFromRole = ref(false);
// Function to remove permission from role
async function removePermissionFromRole(roleId, permissionName) {
  removingPermissionFromRole.value = true;
  try {
    await http.delete(
        `/api/rbac/${defaultClientId.value}/roles/${roleId}/permissions/${permissionName}`
    );
    console.log('Permission removed from role:', permissionName);
    const roleIndex = roles.value.findIndex((role) => role.id === roleId);
    if (roleIndex !== -1) {
      roles.value[roleIndex].granted_permissions = roles.value[
          roleIndex
          ].granted_permissions.filter(
          (permission) => permission.name !== permissionName
      ); // Update local state by removing the permission
    }
  } catch (error) {
    console.error('Error removing permission from role:', error);
  } finally {
    removingPermissionFromRole.value = false;
  }
}

const deletingRole = ref(false);
// Function to delete a role
async function deleteRole(roleId) {
  deletingRole.value = true;
  try {
    await http.delete(
        `/api/rbac/${defaultClientId.value}/roles/${roleId}?force=true`
    );
    console.log('Role deleted:', roleId);
    roles.value = roles.value.filter((role) => role.id !== roleId); // Update local state by removing the deleted role
    // isRoleModalOpen.value = false; // Close the role modal
    selectedRole.value = '';
    users.value.forEach((user) => {
      if (user.role?.id === roleId) {
        user.role = null;
      }
    });
  } catch (error) {
    console.error('Error deleting role:', error);
  } finally {
    deletingRole.value = false;
  }
}

const updatingRole = ref(false);
// Function to edit role name
async function updateRole(roleId, newName) {
  updatingRole.value = true;
  try {
    const response = await http.patch(
        `/api/rbac/${defaultClientId.value}/roles/${roleId}`,
        {
          name: newName?.trim() || selectedRole.value.name,
          omittable_fields: selectedRole.value.omittable_fields || [],
          hideable_fields: selectedRole.value.hideable_fields || [],
        },
        { headers: { 'Content-Type': 'application/json' } }
    );
    console.log('Role name updated:', response.data);
    const finalName = response.data.message?.name;
    const roleIndex = roles.value.findIndex((role) => role.id === roleId);
    if (roleIndex !== -1) {
      roles.value[roleIndex].name = finalName; // Update local state with new role name
    }
    if (selectedRole.value.id === roleId) {
      selectedRole.value.name = finalName;
    }
    isEditingRoleName.value = false;
    /*
    users.value.forEach((user) => {
      if (user.role?.id === roleId) {
        user.role.name = finalName;
      }
    });
     */
  } catch (error) {
    console.error('Error updating role name:', error);
  } finally {
    updatingRole.value = false;
  }
}

/*
async function addSubLocationToGroup(groupId, subEntityId) {
  addingSubLocationToGroup.value = true;
  try {
    const response = await http.post(
      `/api/rbac/${defaultClientId.value}/groups/${groupId}/sub-entities/${subEntityId}`
    );
    console.log('Sub-location added to group:', response.data);
    const groupIndex = groups.value.findIndex((group) => group.id === groupId);
    if (groupIndex !== -1) {
      const groupSubEntities = groups.value[groupIndex].sub_entities || [];
      if (!groupSubEntities.some((subEntity) => subEntity.id === subEntityId)) {
        groups.value[groupIndex].affiliated_merchant_sub_entities =
          response.data.message.affiliated_merchant_sub_entities;
      }
    }
  } catch (error) {
    console.error('Error adding sub-location to group:', error);
  } finally {
    addingSubLocationToGroup.value = false;
  }
}

const removingSubEntityFromGroup = ref(false);
// Function to remove sub-entity from group
async function removeSubEntityFromGroup(subEntityId, groupId) {
  removingSubEntityFromGroup.value = true;
  try {
    const response = await http.delete(
      `/api/rbac/${defaultClientId.value}/groups/${groupId}/sub-entities/${subEntityId}`
    );
    console.log('Sub-entity removed from group:', response.data);
    const groupIndex = groups.value.findIndex((group) => group.id === groupId);
    if (groupIndex !== -1) {
      groups.value[groupIndex].affiliated_merchant_sub_entities = groups.value[
        groupIndex
      ].affiliated_merchant_sub_entities.filter(
        (subEntity) => subEntity.id !== subEntityId
      );
    }
  } catch (error) {
    console.error('Error removing sub-entity from group:', error);
  } finally {
    removingSubEntityFromGroup.value = false;
  }
}
*/

const userAllowedSubEntities = ref([]);
const addingUserSubEntity = ref(false);
const removingUserSubEntity = ref(false);

/*
async function getUserAllowedSubEntities(userId) {
  try {
    const response = await http.get(
      `/api/rbac/${defaultClientId.value}/users/${userId}/sub-entities`
    );
    userAllowedSubEntities.value = response.data.message;
    console.log('User allowed sub-entities:', response.data);
  } catch (error) {
    console.error('Error fetching user allowed sub-entities:', error);
  }
}
*/

const selectedSubEntity = ref(null);
async function addUserAllowedSubEntity(userId, subEntityId) {
  const merchant_id = defaultClientId.value;
  addingUserSubEntity.value = true;
  try {
    const response = await http.post(
        `/api/rbac/${merchant_id}/users/${userId}/sub-entities/${subEntityId}`
    );

    const message = response.data.message;

    const userIndex = users.value.findIndex((user) => user.id === userId);
    if (userIndex !== -1) {
      users.value[userIndex].sub_entities = message.sub_entities;
    }
    if (selectedUser && selectedUser.id === userId) {
      selectedUser.sub_entities = message.sub_entities;
    }
  } catch (error) {
    console.error('Error adding sub-entity to user:', error);
  } finally {
    addingUserSubEntity.value = false;
  }
}

async function removeUserAllowedSubEntity(userId, subEntityId) {
  removingUserSubEntity.value = true;
  try {
    const response = await http.delete(
        `/api/rbac/${defaultClientId.value}/users/${userId}/sub-entities/${subEntityId}`
    );
    console.log('Sub-entity removed from user:', response.data);
    userAllowedSubEntities.value = userAllowedSubEntities.value.filter(
        (subEntity) => subEntity.id !== subEntityId
    );

    const message = response.data.message;

    const userIndex = users.value.findIndex((user) => user.id === userId);
    if (userIndex !== -1) {
      users.value[userIndex].sub_entities = message.sub_entities;
    }

    if (selectedUser && selectedUser.id === userId) {
      selectedUser.sub_entities = message.sub_entities;
    }
  } catch (error) {
    console.error('Error removing sub-entity from user:', error);
  } finally {
    removingUserSubEntity.value = false;
  }
}

/**
 * Helper to get the merchant terminals for the selected role
 */
const merchantTerminalsForSelectedRole = computed(() => {
  if (!selectedRole.value || !selectedRole.value.merchant || !selectedRole.value.merchant.id) {
    return [];
  }

  const merchantId = selectedRole.value.merchant.id;
  return vtMerchantsByMerchantId[merchantId] || [];
});

/**
 * Helper to get the selected terminal for a merchant
 */
const getSelectedTerminalCustomFields = (merchantId) => {
  if (!merchantId || !vtMerchantsByMerchantId[merchantId]) {
    return [];
  }

  const terminalId = selectedTerminalId.value[merchantId];
  if (!terminalId) {
    // If no terminal is selected, return first terminal's fields or empty array
    return vtMerchantsByMerchantId[merchantId][0]?.customFields || [];
  }

  const terminal = vtMerchantsByMerchantId[merchantId].find(t => t.id === terminalId);
  return terminal?.customFields || [];
};

/**
 * @param {CustomField} addedField
 */
const toggleHideableField = async (addedField) => {
  updatingRole.value = true;

  const fieldExists = selectedRoleAvailableFields.value.includes(
      addedField.name
  );

  try {
    if (fieldExists) {
      // Revoke hideable field
      await http.delete(
          `/api/rbac/${defaultClientId.value}/roles/${selectedRole.value.id}/hideable-fields/${encodeURIComponent(addedField.name)}`
      );
      delete selectedRole.value.custom_field_access[addedField.name];
    } else {
      // Grant hideable field
      await http.post(
          `/api/rbac/${defaultClientId.value}/roles/${selectedRole.value.id}/hideable-fields/${encodeURIComponent(addedField.name)}`
      );
      selectedRole.value.custom_field_access[addedField.name] = 'show';
    }
  } catch (error) {
    console.error('Error toggling hideable field:', error);
  } finally {
    updatingRole.value = false;
  }
};

/**
 * @param {CustomField} addedField
 */
const toggleOmittableField = async (addedField) => {
  updatingRole.value = true;

  /*
  if (!selectedRole.value.omittable_fields) {
    selectedRole.value.omittable_fields = [];
  }
  */

  const fieldExists = (selectedRole.value?.custom_field_access?.[addedField.name]) === 'require';

  try {
    if (fieldExists) {
      // Revoke omittable field
      await http.delete(
          `/api/rbac/${defaultClientId.value}/roles/${selectedRole.value.id}/omittable-fields/${encodeURIComponent(addedField.name)}`
      );
      delete selectedRole.value.custom_field_access[addedField.name];
    } else {
      // Grant omittable field
      await http.post(
          `/api/rbac/${defaultClientId.value}/roles/${selectedRole.value.id}/omittable-fields/${encodeURIComponent(addedField.name)}`
      );
      selectedRole.value.custom_field_access[addedField.name] = 'require';
    }
  } catch (error) {
    console.error('Error toggling omittable field:', error);
  } finally {
    updatingRole.value = false;
  }
};

/*
const assigningUserToGroup = ref(false);
// Function to assign user to groups
async function assignUserToGroup(userId, groupId) {
  assigningUserToGroup.value = true;
  try {
    const response = await http.post(
      `/api/rbac/${defaultClientId.value}/users/${userId}/groups/${groupId}`
    );
    console.log('User assigned to group:', userId, groupId);
    const userIndex = users.value.findIndex((user) => user.id === userId);
    if (userIndex !== -1) {
      // const group = groups.value.find((group) => group.id === groupId);
      // if (group) {
      //   users.value[userIndex].groups_member.push(group); // Update local state by adding the group to the user
      // }
      users.value[userIndex].groups_member =
        response.data.message?.groups_member;
    }
    if (selectedUser.id === userId) {
      selectedUser.groups_member = response.data.message?.groups_member;
    }
  } catch (error) {
    console.error('Error assigning user to group:', error);
  } finally {
    assigningUserToGroup.value = false;
  }
}

const filteredUsers = computed(() => {
  if (searchQuery.value.trim() === '') {
    return users.value;
  } else {
    const query = searchQuery.value.trim().toLowerCase();
    return users.value.filter((user) =>
      user.groups.some((groupId) =>
        groups.value.find(
          (group) =>
            group.id === groupId && group?.name.toLowerCase().includes(query)
        )
      )
    );
  }
});

const filteredUsersByGroupAndRole = computed(() => {
  let filtered = users.value;
  console.log('SGF', selectedGroupFilter.value);
  if (selectedGroupFilter.value) {
    filtered = filtered.filter((user) =>
      user.groups_member?.some(
        (group) => group.id === selectedGroupFilter.value
      )
    );
  }

  if (selectedRoleFilter.value) {
    filtered = filtered.filter(
      (user) => user.role.id === selectedRoleFilter.value
    );
  }

  return filtered;
});
*/

watch(isRoleModalOpen, (newValue) => {
  if (!newValue) {
    selectedRole.value = '';
  }
});

watch(isGroupModalOpen, (newValue) => {
  if (!newValue) {
    selectedGroup.value = '';
  }
});

function openUserModal(user) {
  isUserModalOpen.value = true;
  Object.assign(selectedUser, JSON.parse(JSON.stringify(user)));

  selectedUser.groups_member = user.groups_member
      ? [...user.groups_member]
      : [];
}

/*
function manageGroups() {
  isGroupModalOpen.value = true;
}
*/

function manageRoles() {
  isRoleModalOpen.value = true;
}

onMounted(async () => {});
</script>
<template>
  <div class="management-page">
    <div class="mb-3 ms-auto me-auto">
      <span class="dashboard-title">Permissions</span>
    </div>
    <div>
      <div class="top-panel d-flex align-items-center justify-content-between">
        <button class="btn" @click="$router.go(-1)"></button>
        <div>
          <button class="btn btn-secondary" @click="manageRoles">
            Configure Roles
          </button>
        </div>
      </div>
    </div>
    <div v-if="!loadingPermissionsPageData">

      <div class="mt-3 mb-3"></div>
      <div class="permissions-table">
        <table class="table">
          <thead>
          <tr>
            <th style="width: 55%;">
              <label>User</label>
              <input
                  class="form-control"
                  placeholder="Search by group"
                  style="visibility: hidden"
              />
            </th>
            <th style="width: 30%;">
              <!--
              <label>Role</label>
              <select v-model="selectedRoleFilter" class="form-select">
                <option value="">All Roles</option>
                <option v-for="role in roles" :key="role.id" :value="role.id">
                  {{ role.name }} ({{ role?.merchant?.name }})
                </option>
              </select>
              -->
            </th>
            <th style="width: 15%;">
              <label>Actions</label>
              <input
                  class="form-control"
                  placeholder="Search by group"
                  style="visibility: hidden"
              />
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.name || user.email }}</td>
            <!--
            <td>{{ user.role?.name }}  ({{ user.frontend_merchants_rbac_access?.map(e => e.name).join(', ') || user.role?.merchant?.name}})</td>
            -->
            <td>{{
                user?.account?.memberships?.map(m => m?.role?.name)?.filter(Boolean)?.filter((e, i, a) => a.indexOf(e) === i)?.join('/') || ''
              }} ({{
                user?.account?.memberships?.map(m => m?.merchant?.name)?.filter(Boolean)?.filter((e, i, a) => a.indexOf(e) === i)?.join('/') || ''
              }})</td>
            <td>
              <button class="btn btn-primary" @click="openUserModal(user)">
                Edit
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <base-spinner-partial />
    </div>
    <base-modal
        v-model="isUserModalOpen"
        :is-large="true"
        title="Edit User"
        class="edit-user-modal"
    >
      <div name="default">
        <div class="edit-user-content">
          <div class="form-group">
            <label for="userName"
            >Name: {{ selectedUser.name || selectedUser.email }}</label
            >
          </div>
          <!--
          <div class="form-group d-flex align-items-center">
            <label for="userRole" class="me-2">Current Role:</label>
            <div v-if="!selectingNewRoleForUser">
              {{ selectedUser.role?.name && `${selectedUser.role.name} (${selectedUser.role.merchant.name})` || 'No role assigned' }}
              <button
                class="btn btn-secondary text-nowrap ms-2"
                @click="selectingNewRoleForUser = true"
              >
                Edit
              </button>
            </div>
            <div v-else class="d-flex align-items-center">
              <select
                id="userRole"
                v-model="selectedUser.role.id"
                class="form-control me-2"
              >
                <option value="">Select a role</option>

                <option v-for="role in roles" :key="role.id" :value="role.id">
                  {{ role.merchant.name }}: {{ role.name }}
                </option>
              </select>

              <button
                class="btn btn-primary text-nowrap me-2"
                :disabled="assigningRoleToUser"
                @click="assignRoleToUser(selectedUser.id, selectedUser.role.id)"
              >
                Assign Role
              </button>
              <button
                class="btn btn-danger text-nowrap"
                @click="selectingNewRoleForUser = false"
              >
                X
              </button>
            </div>
          </div>
          -->
        </div>
      </div>
      <div class="form-group">
        <label>Sub-Entities from User:</label>
        <div class="sub-entities-list">
          <div class="groups-list">
            <span
                v-for="subEntity in selectedUser.sub_entities"
                :key="subEntity?.id"
                class="badge bg-success me-1"
                :style="{
                cursor: removingUserSubEntity ? 'not-allowed' : 'pointer'
              }"
                @click="
                !removingUserSubEntity &&
                  removeUserAllowedSubEntity(selectedUser.id, subEntity.id)
              "
            >{{ subEntity.name }}</span
            >
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Assign Sub-Entities to User:</label>
        <select v-model="selectedSubEntity" class="form-select me-2">
          <option disabled value="">Select a sub-entity</option>
          <option
              v-for="subEntity in subEntities"
              :key="subEntity.id"
              :value="subEntity.id"
          >
            {{ subEntity.name }} ({{ subEntity.merchant.name }})
          </option>
        </select>
        <button
            class="btn btn-sm btn-success mt-2"
            :disabled="!selectedSubEntity || addingUserSubEntity"
            @click="addUserAllowedSubEntity(selectedUser.id, selectedSubEntity)"
        >
          Add Sub-Entity
        </button>
      </div>

      <div name="footer">
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="isUserModalOpen = false">
            Close
          </button>
          <!-- <button class="btn btn-primary" @click="saveUser">Save</button> -->
        </div>
      </div>
    </base-modal>
    <base-modal :is-large="true" v-model="isRoleModalOpen" title="Configure Roles">
      <div name="default">
        <div v-if="!selectedRole">
          <div class="d-flex mb-3">
            <input
                v-model="newRoleName"
                class="form-control me-2"
                placeholder="Enter new role name"
            />
            <button
                style="width: 150px"
                class="btn btn-success"
                :disabled="creatingNewRole || !newRoleName"
                @click="createNewRole(newRoleName)"
            >
              Add Role
            </button>
          </div>
          <ul class="list-group">
            <li
                v-for="role in roles"
                :key="role.id"
                class="list-group-item list-group-item-action"
                style="cursor: pointer"
                @click="selectedRole = role"
            >
              <div class="container">
                <div class="row">
                  <div class="col-3">
                    {{ role.merchant.name }}
                  </div>
                  <div class="col-9">
                    {{ role.name }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="selectedRole" class="mt-3">
          <button class="btn btn-secondary mb-2" @click="selectedRole = null">
            Back to Role List
          </button>
          <div
              v-if="isEditingRoleName"
              class="d-flex justify-content-between align-items-center mb-2"
          >
            <input v-model="selectedRole.name" class="form-control me-2" />
            <button
                class="btn btn-primary"
                :disabled="updatingRole"
                @click="updateRole(selectedRole.id, selectedRole.name)"
            >
              Save
            </button>
          </div>
          <div
              v-else
              class="d-flex justify-content-between align-items-center mb-2"
          >
            <h5 class="mb-0">Selected Role: {{ selectedRole.name }} ({{ selectedRole.merchant.name }})</h5>
            <button class="btn btn-secondary" @click="isEditingRoleName = true">
              Edit
            </button>
          </div>
          <ul class="list-group">
            <li
                v-for="permission in selectedRole.granted_permissions.sort((a, b) => a.name.localeCompare(b.name))"
                :key="permission.name"
                class="list-group-item d-flex justify-content-between align-items-center"
            >
              {{ permission.name }}
              <button
                  class="btn btn-danger btn-sm"
                  :disabled="removingPermissionFromRole"
                  @click="
                  removePermissionFromRole(selectedRole.id, permission.name)
                "
              >
                Remove
              </button>
            </li>
          </ul>
          <div class="mt-3">
            <select v-model="selectedPermission" class="form-select mb-2">
              <option disabled value="">Please select a permission</option>
              <option
                  v-for="permission in permissions.filter(
                  (p) =>
                    !selectedRole.granted_permissions.some(
                      (rolePermission) => rolePermission.name === p.name
                    )
                )"
                  :key="permission?.name"
                  :value="permission?.name"
              >
                {{ permission?.name }}
              </option>
            </select>
            <button
                class="btn btn-success"
                :disabled="!selectedPermission || addingPermissionToRole"
                @click="
                () => addPermissionToRole(selectedRole.id, selectedPermission)
              "
            >
              Add Permission to Role
            </button>
          </div>

          <div class="mt-3" style="display: none;">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-2">
                {{ selectedRole?.custom_field_access }}
              </h5>
            </div>
          </div>
          <!--
          -->

          <div class="mt-3">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-2">
                Terminal Configuration:
              </h5>
              <span class="text-muted">
                Select which terminal to configure field permissions for.
              </span>
            </div>
            <div class="mb-3">
              <select
                  v-model="selectedTerminalId[selectedRole.merchant.id]"
                  class="form-select"
                  @change="loadCustomFields"
              >
                <option value="" disabled>Select a Terminal</option>
                <option
                    v-for="terminal in merchantTerminalsForSelectedRole"
                    :key="terminal.id"
                    :value="terminal.id"
                >
                  {{ terminal.name || `Terminal ${terminal.index + 1}` }}
                  {{ terminal.is_active ? '' : '(Inactive)' }}
                </option>
              </select>
            </div>
          </div>

          <div class="mt-3">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-2">
                Available Fields for {{ selectedRole.name }}:
              </h5>
              <span class="text-muted">
                When checked, users with this role will be able to view and
                submit data in the virtual terminal for the custom field.
              </span>
            </div>
            <ul class="list-group">
              <li
                  v-for="field in getSelectedTerminalCustomFields(selectedRole.merchant.id)"
                  :key="field.name"
                  class="list-group-item d-flex justify-content-between align-items-center"
              >
                {{ field.name }}
                <input
                    type="checkbox"
                    :disabled="updatingRole"
                    :checked="!!selectedRole?.custom_field_access?.[field.name]"
                    @change="toggleHideableField(field)"
                />
              </li>
            </ul>
            <div class="mt-3">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-2">
                  Required Fields for {{ selectedRole.name }}:
                </h5>
                <span class="text-muted">
                  Like Available fields, but it will also be required to submit.
                </span>
              </div>
              <ul class="list-group">
                <li
                    v-for="field in getSelectedTerminalCustomFields(selectedRole.merchant.id)"
                    :key="field.name"
                    class="list-group-item d-flex justify-content-between align-items-center"
                >
                  {{ field.name }}
                  <input
                      type="checkbox"
                      :disabled="updatingRole"
                      :checked="selectedRole?.custom_field_access?.[field.name] === 'require'"
                      @change="toggleOmittableField(field)"
                  />
                </li>
              </ul>
            </div>
            <div name="footer">
              <div class="modal-footer">
                <button
                    class="btn btn-danger"
                    :disabled="deletingRole"
                    @click="deleteRole(selectedRole.id)"
                >
                  Delete Role
                </button>
                <button
                    class="btn btn-secondary"
                    @click="isRoleModalOpen = false"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-modal>
  </div>
</template>
<style lang="scss">
.top-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-title {
  font-size: 24px;
  color: #383838;
}
.permissions-table {
  margin-top: 20px;
}
</style>