<template>
  <span class="point btn" @click="refreshData"
    ><refresh :size="20" /><span class="refresh-text">Refresh</span></span
  >

  <!--
  <span class="point btn" @click="handleSave">
    <pin :size="20" />
    <span class="refresh-text">Save Current View</span>
  </span>
  -->

  <span class="point btn" @click="resetState"
    ><restore :size="20" /><span class="refresh-text">Reset View</span></span
  >
  <span v-if:="p.counter" class="refresh-text"
    >&nbsp;&nbsp;TOTAL: <strong>{{ total }}</strong
    >&nbsp;&nbsp;AVG: <strong>{{ avg }}</strong
    >&nbsp;&nbsp;COUNT: <strong>{{ count2 }}</strong></span
  >
  <span class="point btn float-md-end" @click="exportSheet"
    ><export :size="20" /><span class="refresh-text">Export Excel</span></span
  >
  <hr v-if="savingLoadingEnabled" style="margin: 3px" />
  <div v-if="savingLoadingEnabled" class="d-flex">
    <div class="point my-2 me-1">
      <button
        class="refresh-text btn btn-sm btn-outline-secondary"
        :disabled="loadAgGridUserLoading"
        @click="loadAgGridUserSettings"
      >
        Load&nbsp;Column&nbsp;State&nbsp;From&nbsp;User&nbsp;Settings
        <web-sync v-if="loadAgGridUserLoading" :size="15" />
      </button>
    </div>
    <div class="point my-2 me-1">
      <button
        class="refresh-text btn btn-sm btn-outline-secondary"
        :disabled="saveAgGridUserLoading"
        @click="saveAgGridUserSettings"
      >
        Save&nbsp;Column&nbsp;State&nbsp;To&nbsp;User&nbsp;Settings
        <web-sync v-if="saveAgGridUserLoading" :size="15" />
      </button>
    </div>
  </div>
  <ag-grid-vue
    :style="'width:' + getWidth() + '; height:' + getHeight() + ';'"
    class="ag-theme-balham"
    domLayout="normal"
    rowSelection="single"
    groupDisplayType="multipleColumns"
    :defaultColDef="defaultColDef"
    :columnDefs="columnDefs"
    :filterOptions="filterOptions"
    :sideBar="sidebar"
    :statusBar="statusbar"
    :pagination="pagination"
    :paginationAutoPageSize="false"
    :grid-options="{
      paginationPageSize: 500,
      paginationAutoPageSize: false,
    }"
    :rowGroupPanelShow="rowGroupPanelShow"
    :autoGroupColumnDef="autoGroupColumnDef"
    :enableRangeSelection="p.enableRangeSelection"
    :excelStyles="excelStyles"
    :aggFuncs="aggFuncs"
    @grid-ready="onGridReady"
    @filterChanged="$emit('filterChanged', filtermodified())"
    @gridColumnsChanged="columnChanged"
    @rowClicked="$emit('rowSelected', rowDetails())"
    @rowDoubleClicked="$emit('row2Selected', rowDetails())"
    @first-data-rendered="params => emit('firstDataRendered', params)"
  >
  </ag-grid-vue>
</template>

<script setup>
import { AgGridVue } from 'ag-grid-vue3';
import { onMounted, onBeforeMount, ref, defineProps, defineExpose } from 'vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import http from '@/services/http';
import Pin from 'vue-material-design-icons/Pin.vue';
import Refresh from 'vue-material-design-icons/Refresh.vue';
import Restore from 'vue-material-design-icons/Restore.vue';
import Export from 'vue-material-design-icons/Export.vue';
import WebSync from 'vue-material-design-icons/WebSync.vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

const saveAgGridUserLoading = ref(false);
const loadAgGridUserLoading = ref(false);
const store = useStore();
const emit = defineEmits(['firstDataRendered', 'row2Selected']);
const toast = useToast();
let toastdefault = {
  position: 'top-center',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false
};

/**
 * only here because vue without typescript doesn't quite work right
 *
 * @type {{
 *   apiurl: string,
 *   height: string,
 *   width: string,
 *   aggFunc: Record<string, *>,
 *   columns: Array,
 *   colDefs: Array<import('ag-grid-community').ColDef>,
 *   filterOptions: Record<string, *>
 *   moveFront: Array
 *   defineDefs: Array<import('ag-grid-community').ColDef>
 *   sideBar: boolean
 *   statusBar: {}
 *   groupPanel: 'always' | string
 *   excelOptions: { fileName?: string }
 *   excelStyles: Array<{
 *     id: 'dateType' | string
 *     dataType: 'dateTime' | string
 *     numberFormat: { format: 'yyyy-mm-dd hh:mm:ss' | string }
 *   }>
 *   enableRangeSelection: boolean
 *   pagination: 'true' | 'false' | string
 *   counter: boolean
 *   totalColumnName: string
 *   getApiDataOnReadyEnabled: boolean
 *   gridConfigKey: string
 * }}
 */
const p = defineProps({
  apiurl: { type: [Function, String] },
  height: { type: String },
  width: { type: String },
  aggFunc: { type: Object },
  columns: { type: Array },
  colDefs: { type: Array },
  filterOptions: { type: Object },
  moveFront: { type: Array },
  defineDefs: { type: Array },
  /**
   * Shows the right hand sidebar for filtering
   */
  sideBar: { type: Boolean, default: false },
  statusBar: { type: Object },
  groupPanel: { type: String, default: 'always' },
  excelOptions: { type: Object },
  excelStyles: { type: Object },
  enableRangeSelection: { type: Boolean, default: true },
  pagination: { type: String, default: 'true' },
  counter: { type: Boolean, default: false },
  /**
   * which column should summed up for the total display,
   * if '' then use "amount" as before (default: '').
   */
  totalColumnName: { type: String, default: '' },
  getApiDataOnReadyEnabled: { type: Boolean, default: true },
  gridConfigKey: { type: String, default: '' },
});
defineExpose({
  getAPI,
  refreshAPI,
  getData,
  setTheRowData,
  resetState,
  setTheColumnDefs
});
const avg = ref(0);
const total = ref(0);
const count2 = ref(0);
const hiddenColumns = ['id', 'hashcode'];
const statusbar = ref(p.statusBar || {});
const excelStyles = ref(p.excelStyles || []);
const filterOptions = ref(p.filterOptions || null);
const pagination = ref(p.pagination || true);
const sidebar = {
  hiddenByDefault: true,
  position: 'right',
  defaultToolPanel: 'filters',
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel'
    },
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel'
    }
  ]
};
const aggFuncs = {
  total: (params) => {
    let total = 0;
    params.values.forEach((value) => {
      if (value) {
        total += parseFloat(value) || 0;
      }
    });
    return total.toFixed(2);
  },
  avg2: (params) => {
    let sum = 0;
    let count = 0;
    params.values.forEach((value) => {
      if (value) {
        sum += parseFloat(value) || 0;
        count += 1;
      }
    });
    let avg = sum / count;
    return avg.toFixed(2) || 0.0;
  },
  count2: (params) => {
    let count2 = 0;
    params.values.forEach((value) => {
      if (value) {
        count2 += 1;
      }
    });
    return count2;
  }
};
const rowGroupPanelShow = ref(null);
const defaultColDef = {
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true,
  sortable: true,
  filter: true,
  resizable: true,
  useValueFormatterForExport: true
};
/** @type {import("vue").Ref<import("ag-grid-community").GridApi>} */
const gridApi = ref(null);
/** @type {import("vue").Ref<import("ag-grid-community").ColumnApi>} */
const colApi = ref(null);

const savingLoadingEnabled = !!p.gridConfigKey;
async function saveAgGridUserSettings() {
  // setting this enables the functionality
  if (!p.gridConfigKey) return;

  saveAgGridUserLoading.value = true;
  try {
    await http({
      url: `/api/user/grid-config/${p.gridConfigKey}`,
      data: colApi.value.getColumnState(),
      config: { headers: { 'content-type': 'application/json' } },
      headers: { 'content-type': 'application/json' },
      method: 'PUT'
    })
  } finally {
    saveAgGridUserLoading.value = false;
  }
}

async function loadAgGridUserSettings() {
  // setting this enables the functionality
  if (!p.gridConfigKey) return;

  loadAgGridUserLoading.value = true;
  try {
    let response = await http.get(`/api/user/grid-config/${p.gridConfigKey}`);
    // doc = https://www.ag-grid.com/javascript-data-grid/column-state/#:~:text=%7D%0A%20%20%20%20%5D%2C-,applyOrder,-%3A%20true%0A%7D
    colApi.value.applyColumnState({ state: response.data.message, applyOrder: true });
  } finally {
    loadAgGridUserLoading.value = false;
  }
}


// Update the toolSet function in your AgGrid.vue component

function toolSet() {
  if (p.counter) {
    let tot = 0.0;
    let count = 0;
    let validValueCount = 0;

    /**
     * @type {import("ag-grid-community").ColDef | null}
     */
    let colDef = null;

    // Find the column definition for the totalColumnName
    if (p.totalColumnName !== "") {
      let colDefs = gridApi.value.getColumnDefs()
          .flatMap(function thing(cd) {
            if (cd.children && cd.children.length) {
              return cd.children.flatMap(thing);
            }
            return [cd];
          });

      let colHeads = colDefs.reduce((a, e) => {
        a[e.headerName] = e;
        return a;
      }, {});

      colDef = colHeads[p.totalColumnName];
    }

    // Process each row after filtering
    gridApi.value.forEachNodeAfterFilter((node) => {
      if (!node.data) return;

      let value = 0;

      if (colDef === null) {
        // Legacy approach - use node.data[1].amount directly
        if (node.data[1] && typeof node.data[1].amount !== 'undefined') {
          value = parseFloat(node.data[1].amount) || 0.0;
        }
      } else {
        // New approach - use the valueGetter from the colDef
        try {
          // First try using valueGetter if available
          if (colDef.valueGetter) {
            let params = {
              data: node.data,
              node: node,
              colDef: colDef,
              api: gridApi.value,
              columnApi: colApi.value,
              context: null
            };

            let rawValue = colDef.valueGetter(params);

            // Handle different return types from valueGetter
            if (rawValue === null || rawValue === undefined) {
              // Skip nulls
              value = 0;
            } else if (typeof rawValue === 'number') {
              // Handle numeric returns
              value = rawValue;
              validValueCount++;
            } else if (typeof rawValue === 'string' && !isNaN(parseFloat(rawValue))) {
              // Handle numeric strings
              value = parseFloat(rawValue);
              validValueCount++;
            } else {
              // Skip non-numeric values
              value = 0;
            }
          } else if (colDef.field) {
            // Try using field if no valueGetter
            value = parseFloat(node.data[colDef.field]) || 0.0;
            if (value !== 0) validValueCount++;
          }
        } catch (err) {
          console.error('Error calculating total:', err);
          value = 0;
        }
      }

      // Add to running total
      tot += value;

      // Count valid transactions (with successful payment status)
      const paymentStatus = node.data[1]?.payment_status || "False";
      if (paymentStatus !== "False") {
        count += 1;
      }
    });

    // Calculate average based on valid values
    let average = 0;
    if (count > 0) {
      average = tot / (validValueCount > 0 ? validValueCount : count);
    }

    // Update UI values with proper formatting
    count2.value = count;
    total.value = tot.toFixed(2);
    avg.value = average.toFixed(2);
  }
}

function getHeight() {
  return p.height || '92%';
}
function getWidth() {
  return p.width || '100%';
}
function rowDetails() {
  return gridApi.value.getSelectedNodes();
}
function filtermodified() {
  toolSet();
  return gridApi.value.getFilterModel();
}

function exportSheet() {
  // Enhanced Excel export with Windows compatibility fixes
  const exportParams = {
    ...p.excelOptions,

    // Process cell values before export to ensure Excel compatibility
    processCellCallback: function(params) {
      // Skip processing header cells
      if (params.node && params.node.group) {
        return params.value;
      }

      // Handle null/undefined values consistently
      if (params.value === null || params.value === undefined) {
        return '';
      }

      // Special handling for dates
      if (params.column.colDef.cellClass === 'dateType') {
        // Return a standard date string format for Excel
        if (params.value instanceof Date) {
          return params.value.toISOString().split('T')[0];
        }

        // If it's a date string, convert to proper date format
        if (typeof params.value === 'string' && params.value !== 'n/a') {
          try {
            const date = new Date(params.value);
            if (!isNaN(date.getTime())) {
              return date.toISOString().split('T')[0];
            }
          } catch (e) {
            // If date parsing fails, return as is
          }
        }
      }

      // Handle numbers with proper Excel formatting
      if (typeof params.value === 'number' ||
          (typeof params.value === 'string' && !isNaN(parseFloat(params.value)) &&
              params.column.colDef.filter === 'agNumberColumnFilter')) {

        // Convert to number if it's a numeric string
        if (typeof params.value === 'string') {
          return parseFloat(params.value);
        }
        return params.value;
      }

      // Handle boolean values
      if (typeof params.value === 'boolean') {
        return params.value ? 'Yes' : 'No';
      }

      // For text cells, sanitize content that might break Excel
      if (typeof params.value === 'string') {
        // Remove control characters that break Excel XML
        // eslint-disable-next-line no-control-regex
        const sanitized = params.value.replace(/[\x00-\x09\x0B\x0C\x0E-\x1F\x7F]/g, '');

        // Limit string length to prevent Excel issues
        if (sanitized.length > 32767) { // Excel cell character limit
          return sanitized.substring(0, 32767);
        }

        return sanitized;
      }

      // Default fallback
      return params.value;
    },

    // Process headers to ensure they're Excel-compatible
    processHeaderCallback: function(params) {
      if (!params.column.getColDef().headerName) {
        return '';
      }
      // Clean header names
      // eslint-disable-next-line no-control-regex
      return params.column.getColDef().headerName.replace(/[\x00-\x09\x0B\x0C\x0E-\x1F\x7F]/g, '');
    },

    // Fix Excel cell formatting
    cellStylesExcelMode: 'forceExcel'
  };

  gridApi.value.exportDataAsExcel(exportParams);
}

function refreshData() {
  if (typeof p.apiurl === 'function') {
    Promise.resolve(p.apiurl())
      .then(result => {
        gridApi.value.setRowData(result);
      });

    return;
  }

  http.get(p.apiurl).then((data) => {
    if (data.data.message) {
      data['data'] = data.data.message;
    }
    gridApi.value.setRowData(data.data);
    toolSet();
  });
}

function setTheRowData(information) {
  gridApi.value.setRowData(information);
}

function getAPI() {
  store.commit('setRouterLoading', true);
  return http.get(p.apiurl).then((data) => {
    if (data.data.message) {
      data['data'] = data.data.message;
    }
    gridApi.value.setRowData(data.data);
    if (!p.defineDefs) {
      setDefs();
    } else {
      gridApi.value.setColumnDefs(p.defineDefs);
    }
    toolSet();
  })
    .then((_, __) => {
      store.commit('setRouterLoading', false);
    });
}

function refreshAPI(url, callback = null) {
  http.get(url).then((data) => {
    if (data.data.message) {
      data['data'] = data.data.message;
    }
    if (Object.keys(data.data).length === 0) {
      gridApi.value.setRowData([]);
    } else {
      gridApi.value.setRowData(data.data);
    }
    toolSet();

    if (callback) {
      try {
          callback();
      } catch (e) {
        // empty
      }
    }
  });
}

function getData() {
  let data = [];
  gridApi.value.forEachNode((rowNode, index) => {
    data.push(rowNode.data);
  });
  return data;
}

function handleSave() {
  let url = p.apiurl.split('?')[0];
  localStorage.setItem(
    url + 'filter',
    JSON.stringify(gridApi.value.getFilterModel())
  );
  localStorage.setItem(
    url + 'column',
    JSON.stringify(colApi.value.getColumnState())
  );
}

function resetState() {
  if (typeof p.apiurl === 'string') {
  let url = p.apiurl.split('?')[0];
  localStorage.setItem(url + 'filter', JSON.stringify([]));
  localStorage.setItem(url + 'column', JSON.stringify([]));
  }
  colApi.value.resetColumnState();
  gridApi.value.setFilterModel(filterOptions.value);
  if (p.moveFront) {
    colApi.value.moveColumns(p.moveFront, 0);
  }
  gridApi.value.sizeColumnsToFit()
  gridApi.value.refreshCells({ force: true })
}

const onGridReady = async (params) => {
  gridApi.value = params.api;
  colApi.value = params.columnApi;
  // TODO test and uncomment if this works
  // if (p.getApiDataOnReadyEnabled) getAPI();
  let promise = getAPI();
  autoSizeAll()
  await promise;

  // so it seems like your saving actually worked
  // todo remove me if we ever get multiple saves
  // or decide to prioritize group vs user vs etc...
  // currently commented as not sure if good idea
  // await loadAgGridUserSettings();
};

function columnChanged() {
  if (colApi.value) {
    if (!p.width) {
      colApi.value.autoSizeAllColumns();
    }
    // localStorage.setItem(p.apiurl+'column', JSON.stringify(colApi.value.getColumnState()))
  }
}

function setTheColumnDefs(columnDefinitions) {
  gridApi.value.setColumnDefs(columnDefinitions);
}

function setDefs() {
  console.log('weAreCallingSetDefs')
  function setDef(key, i) {
    let hidden = false;
    if (hiddenColumns.includes(key)) {
      hidden = true;
    }
    return {
      headerName: key,
      valueGetter: (p) => {
        if (!p.data) return '';
        return p.data[i];
      },
      hide: hidden,
      enablePivot: true
    };
  }
  const colDefs = [];
  let largest_record = 0;
  let max_length = 0;
  gridApi.value.forEachNode((node) => {
    let len = Object.keys(node.data).length;
    if (len > max_length) {
      (max_length = len), (largest_record = node.rowIndex);
    }
  });
  const row = gridApi.value.getRowNode(largest_record).data;
  if (Array.isArray(row)) {
    if (!p.columns) {
      row.forEach((key, i) => colDefs.push(setDef(key, i)));
    } else {
      p.columns.forEach((key, i) => colDefs.push(setDef(key, i)));
    }
  } else {
    const keys = Object.keys(row).sort();
    keys.forEach((key) => {
      if (Array.isArray(row[key])) {
        colDefs.push({
          headerName: ' ',
          children: [
            {
              field: key,
              enableRowGroup: true,
              enablePivot: true,
              valueGetter: (p) => JSON.stringify(p.data[key])
            }
          ]
        });
      } else if (typeof row[key] == 'object' && row[key]) {
        let _keys = [];
        Object.keys(row[key])
          .sort()
          .forEach((k) => {
            _keys.push({
              field: k,
              enableRowGroup: true,
              valueGetter: (p) => {
                if (!p.data) return '';
                if (!p.data[key]) return '';
                return p.data[key][k] || '';
              }
            });
          });
        colDefs.push({
          headerName: key.toUpperCase(),
          children: _keys
        });
      } else {
        colDefs.push({ field: key });
      }
    });
  }

  columnDefs.value = colDefs;
  if (p.colDefs) {
    colDefs.push(...p.coldDefs);
  }
  gridApi.value.setColumnDefs(colDefs);
}

const columnDefs = ref([]);
const autoGroupColumnDef = {
  valueFormatter: ({ value, data }) => 'Grouped ' + value,
  groupMaintainOrder: true,
  field: 'model',
  cellRenderer: 'agGroupCellRenderer',
  cellRendererParams: {
    checkbox: false
  }
};

function autoSizeAll() {
  gridApi.value.closeToolPanel();
  gridApi.value.setSideBarVisible(p.sideBar);

  let url = p.apiurl.split('?')[0];
  if (p.moveFront) {
    colApi.value.moveColumns(p.moveFront, 0);
  }
  colApi.value.applyColumnState({
    state: JSON.parse(localStorage.getItem(url + 'column')),
    applyOrder: true
  });
  // gridApi.value.sizeColumnsToFit()
  if (!p.width) {
    colApi.value.autoSizeAllColumns();
  }
  gridApi.value.setFilterModel(
    JSON.parse(localStorage.getItem(url + 'filter'))
  );
  if (Object.keys(gridApi.value.getFilterModel()).length === 0) {
    gridApi.value.setFilterModel(filterOptions.value);
  }
}

onBeforeMount(() => {
  rowGroupPanelShow.value = p.groupPanel;
});

onMounted(() => {});
</script>

<style scoped>
.point {
  cursor: pointer;
}
.refresh-text {
  font-size: 14px;
  color: var(--c-dark);
  vertical-align: middle;
  margin-left: 5px;
}
html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 1;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
