import { PERMISSION_PRIMITIVES } from '@/config/constants';

/**
 * Handle RBAC behavior
 *
 * @param {import("vue").Ref<{id: string, name: string}>} computedMerchantFromApiKey
 * @param {import("vue").ComputedRef<boolean>} merchantHasPermissions
 * @param {import("vuex").Store<StoreState>} store
 * @param {Object} paramsObj
 * @param {import("vue-toastification").ToastInterface} toast
 * @param {import("vue-router").Router} router
 * @param {boolean} isSwipeOnly
 * @return {boolean} whether the user has permission
 */
export function processUserPermissions(
    computedMerchantFromApiKey,
    merchantHasPermissions,
    store,
    paramsObj,
    toast,
    router,
    isSwipeOnly
) {
  if (merchantHasPermissions.value && !!store.state.user) {
    paramsObj.billingAddress = false;

    // const default_options = ['credit_card', 'bank', 'swipe'];
    const permissionSet = new Set(store.getters.grantedPermissions);
    console.log('ALL PERMISSIONS FOR ROLE:', permissionSet);

    // eslint-disable-next-line no-inner-declarations
    function permOnThis(perm) {
      return store.getters.hasPermissionOnMerchant(
          perm,
          computedMerchantFromApiKey.value?.id
      );
    }

    if (isSwipeOnly) {
      if (permOnThis(PERMISSION_PRIMITIVES.TRANSACTION_SWIPE)) {
        paramsObj.paymentOptions = ['swipe'];
        return true;
      }
      paramsObj.paymentOptions = [];
      return false;
    }

    /**
     * These come in from the pre-configured virtual terminal options.
     * In order to render a payment option, all three must be true:
     *  - It is not swipe only configuration (this only ever shows swipe)
     *  - The payment option is included in the originalPaymentOptions
     *  - The user has the relevant permission to view this option
     */
    if (!permOnThis(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL)) {
      toast.error('User does not have VIRTUAL_TERMINAL permission.');
      return false;
    }

    const NEEDS = {
      [PERMISSION_PRIMITIVES.TRANSACTION_CHARGE]: 'credit_card',
      [PERMISSION_PRIMITIVES.TRANSACTION_DEBIT_ACH_CHARGE]: 'bank',
      [PERMISSION_PRIMITIVES.TRANSACTION_CASH]: 'cash',
      [PERMISSION_PRIMITIVES.TRANSACTION_SWIPE]: 'swipe',
      [PERMISSION_PRIMITIVES.TRANSACTION_APPLE_PAY]: 'apple_pay',
      [PERMISSION_PRIMITIVES.TRANSACTION_GOOGLE_PAY]: 'google_pay',
    };

    for (let key in NEEDS) {
      if (!permOnThis(key)) {
        paramsObj.paymentOptions = paramsObj.paymentOptions.filter(
            (e) => e !== NEEDS[key]
        );
      }
    }

    if (paramsObj.paymentOptions.length === 0) {
      toast.error('User does not have permissions to use any payment methods.');
      // router.push('/');
      return false;
    }

    if (permissionSet.has(PERMISSION_PRIMITIVES.OMIT_CC_ZIP)) {
      paramsObj.zipMode = null;
    }
  }

  return true;
}

/**
 * Get custom fields for a terminal based on role permissions
 *
 * @param {Array<Object>} customFields - The custom fields from the terminal
 * @param {Object} role - The user's role with permissions
 * @return {Array<Object>} The filtered custom fields based on permissions
 */
export function getCustomFieldsByPermissions(customFields, role) {
  if (!customFields || !customFields.length || !role) {
    return customFields;
  }

  return customFields.map(cf => {
    // If role has custom_field_access property
    if (role.custom_field_access && role.custom_field_access[cf.name]) {
      const vis = role.custom_field_access[cf.name];

      if (vis === 'require' || vis === 'show') {
        return {
          ...cf,
          required: vis === 'require',
          requireForGuest: false, // By definition, these are not guest users
          is_hidden: false
        };
      } else {
        return false; // Filter this field out
      }
    }

    // Legacy support for older permission structures
    if (role.omittable_fields && role.omittable_fields.some(field => field.id === cf.id)) {
      return {
        ...cf,
        required: false
      };
    }

    if (role.hideable_fields && role.hideable_fields.some(field => field.id === cf.id)) {
      return {
        ...cf,
        is_hidden: true
      };
    }

    return cf;
  }).filter(Boolean); // Remove fields that were set to false
}

/**
 * Get all terminal IDs from a merchant that a user has access to
 *
 * @param {Array<Object>} terminals - Array of terminals
 * @param {Object} user - User object
 * @param {Object} store - Vuex store
 * @return {Array<string>} Array of terminal IDs user has access to
 */
export function getAccessibleTerminals(terminals, user, store) {
  if (!terminals || !terminals.length || !user) {
    return [];
  }

  // Default to all terminals if no permissions defined
  if (!store.getters.merchantHasPermissions) {
    return terminals.map(t => t.id);
  }

  const hasVirtualTerminalPermission = store.getters.grantedPermissions.includes(
      PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL
  );

  // No access if missing the basic permission
  if (!hasVirtualTerminalPermission) {
    return [];
  }

  // Return all terminal IDs (permissions are checked at terminal level)
  return terminals.map(t => t.id);
}